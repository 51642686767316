/* Background image styling */
body {
  background-image: url('./imgs/bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #000;
  min-height: 100vh;
  margin: 0;
}

/* Fixed resizeable message field */
#message-field {
  resize: none;
}

/* Update Button Inline Styling */
#update-submit {
  margin-left: 1rem;
}

/* Form Box */
.box {
  opacity: 0.95;
}

/* Values Description */
.is-description {
  font-weight: normal;
}

.padding-fix {
  padding: 0rem 1.5rem;
}

.button {
  margin: 0 4px;
}

/* Message Form */
.message-form-buttons {
  display: flex;
  justify-content: space-between;
}
